<template lang="html">
    <b-container class="filters">
        <div class="filters-container">
            <filters
                :campaigns="campaigns"
                :medias="medias"
                :alerts="alerts"
                @on-select-all-status="allstatus"
                @on-select-none-status="nonestatus"
                @on-filter-by-status="filterByStatus"
                @on-filter-history-campaigns="archived"
            />
        </div>

        <div v-if="status.includes('999')" class="alerts-container">
            <span style="font-size: 15px">Alertes</span>
            <div style="display: flex; align-items: baseline; padding-top: 1rem">
                <div v-if="alerts.length > 0" class="filter">
                    <label style="font-size: 13px; font-weight: 400">Filtrer par :</label>
                    <b-dropdown text="Type" id="dropdown-filter" checkbox-menu allow-focus no-flip ref="dropdown" size="sm" class="alert-dropdown">
                        <template #button-content>
                            <div class="list">
                                Type {{ filterByAlertType.length > 0 ? `(${filterByAlertType.length})` : '' }}
                                <img src="@/assets/images/icons/array-down-icon.svg" style="width: 16px; height: 16px" />
                            </div>
                        </template>
                        <b-dropdown-form>
                            <b-form-checkbox-group stacked size="m" v-model="filterByAlertType" :options="sortedAlertsByType"></b-form-checkbox-group>
                        </b-dropdown-form>
                    </b-dropdown>

                    <b-dropdown text="Média" id="dropdown-filter" checkbox-menu allow-focus no-flip ref="dropdown" size="sm" class="media-dropdown">
                        <template #button-content>
                            <div class="list">
                                Média {{ filterByMediaAlert.length > 0 ? `(${filterByMediaAlert.length})` : '' }}
                                <img src="@/assets/images/icons/array-down-icon.svg" style="width: 16px; height: 16px" />
                            </div>
                        </template>
                        <b-dropdown-form style="width: 200px; max-width: 200px">
                            <b-form-checkbox-group v-model="filterByMediaAlert" :options="transformedMediasAlerts"></b-form-checkbox-group>
                        </b-dropdown-form>
                    </b-dropdown>

                    <b-dropdown text="Marque" id="dropdown-filter" checkbox-menu allow-focus no-flip ref="dropdown" size="sm" class="media-dropdown">
                        <template #button-content>
                            <div class="list">
                                Marque {{ filterByBrand.length > 0 ? `(${filterByBrand.length})` : '' }}
                                <img src="@/assets/images/icons/array-down-icon.svg" style="width: 16px; height: 16px" />
                            </div>
                        </template>
                        <b-dropdown-form style="width: 190px; max-width: 190px">
                            <b-form-checkbox-group stacked size="m" v-model="filterByBrand" :options="sortedAlertsBrands"></b-form-checkbox-group>
                        </b-dropdown-form>
                    </b-dropdown>
                </div>

                <div style="padding-left: 0.5rem">
                    <b-form-group label="" label-for="start-date-select" class="custom-label">
                        <label style="font-size: 13px; font-weight: 400; padding-right: 1rem">Trier par :</label>
                        <b-form-select
                            id="start-date-select"
                            class="sort-select-group sort-select-option-first"
                            :autofocus="true"
                            v-model="sortAlertBy"
                            style="cursor: pointer; width: 200px; font-size: 13px"
                        >
                            <b-form-select-option v-for="option in dateSortOptions" :value="option.value" class="sort-select-option">{{ option.label }} </b-form-select-option></b-form-select
                        >
                    </b-form-group>
                </div>
            </div>
        </div>

        <b-card class="campaigns-container" v-if="!status.includes('999')">
            <campaign-table
                @filter-by-brand="filterByBrand"
                :filterByMediaAlert="filterByMediaAlert"
                :filterByAlertType="filterByAlertType"
                :is-loading="isLoading"
                :campaigns="campaignFilteredByStatus"
                :medias="mediaFilteredByStatus"
                :alerts="alertsFilteredByTypeAndBrand"
                :right="right"
                @on-refresh="handleRefresh"
                :current-table="currentTable"
                :archived-campaigns="archivedCampaigns"
                :alertTopTenCount="alertTopTenCount"
                :alertReportVigilantCount="alertReportVigilantCount"
                :alertReportCriticalCount="alertReportCriticalCount"
            >
            </campaign-table>
        </b-card>

        <div class="campaigns-container" v-else>
            <campaign-table
                :is-loading="isLoading"
                :campaigns="campaignFilteredByStatus"
                :filterByAlertType="filterByAlertType"
                :filterByMediaAlert="filterByMediaAlert"
                :medias="mediaFilteredByStatus"
                :alerts="alertsFilteredByTypeAndBrand"
                :right="right"
                @on-refresh="handleRefresh"
                :archived-campaigns="archivedCampaigns"
                :alertTopTenCount="alertTopTenCount"
                :current-table="currentTable"
                :alertReportVigilantCount="alertReportVigilantCount"
                :alertReportCriticalCount="alertReportCriticalCount"
            >
            </campaign-table>
        </div>
    </b-container>
</template>

<script>
    import Filters from './filters.vue';
    import CampaignTable from './table.vue';
    import { mapGetters } from 'vuex';
    import { BCard } from 'bootstrap-vue';
    import moment from 'moment';
    import _ from 'lodash';

    export default {
        components: {
            Filters,
            CampaignTable,
            BCard,
        },

        data() {
            return {
                options: [{ text: 'Date de fin', value: 'end date' }],
                colorAlert: 'success',
                campaignCode: '',
                right: '',
                popupCampaign: false,
                startDateValueCampaign: '',
                endDateValueCampaign: '',
                startDate: '',
                startDateCode: '',
                archivedCampaigns: [],
                isLoading: false,

                alertUnlinkCount: 0,
                alertTopTenCount: 0,
                alertReportVigilantCount: 0,
                alertReportCriticalCount: 0,

                alertCSMCriticalCount: 0,
                alertCSMVigilantCount: 0,

                alertDirectionCriticalCount: 0,
                alertDirectionVigilantCount: 0,

                alertTopTenCritical: [],
                alertReportCritical: [],
                alertCSMCritical: [],
                alertDirectionCritical: [],

                alertReportVigilant: [],
                alertCSMVigilant: [],
                alertDirectionVigilant: [],

                filterByBrand: [],
                filterByAlertType: [],
                filterByMediaAlert: [],

                dateSortOptions: [
                    { label: 'Par défaut', value: 'default' },
                    { label: 'Date de début (ASC)', value: 'startDateAsc' },
                    { label: 'Date de début (DESC)', value: 'startDateDesc' },
                    { label: 'Date de fin (ASC)', value: 'endDateAsc' },
                    { label: 'Date de fin (DESC)', value: 'endDateDesc' },
                ],
                sortAlertBy: 'default',
                mediasMap: {
                    FBK: 'Facebook',
                    FBF: 'Facebook',
                    YOU: 'Youtube',
                    VID: 'Vidéo',
                    DSK: 'Ordinateur',
                    NTV: 'Native',
                    LIN: 'Linkedin',
                    ADW: 'Adwords',
                    MOB: 'Mobile',
                    AWR: 'Adwords Fil Rouge',
                },
            };
        },

        computed: {
            ...mapGetters({
                availableMediasCode: 'app/availableMediasCode',
                status: 'app/getStatusFilters',
                campaigns: 'app/getCurrentCampaignsList',
                medias: 'app/getCurrentMediasList',
                alerts: 'app/getCurrentAlertsList',
                globalAlerts: 'app/getGlobalAlerts',
                capitalizedBrands: 'app/getBrandsList',
                brandsAlerts: 'app/getBrandsListAlerts',
                alertTypes: 'app/getAlertsTypes',
                groupedAlerts: 'app/getGroupedAlerts',
                capitalizedMedias: 'app/getMediasList',
                mediasAlerts: 'app/getMediasFromAlerts',
                globalConfigPageCampaigns: 'app/getGlobalConfigPageCampaigns',
            }),

            transformedMediasAlerts() {
                let uniquesMediasMap = {};
                this.mediasAlerts.forEach((media) => {
                    if (media.label) {
                        uniquesMediasMap[media.key] = media;
                    }
                });

                return Object.values(uniquesMediasMap)
                    .sort((a, b) => a.key.localeCompare(b.key))
                    .map((media) => media.label);
            },

            campaignFilteredByStatus() {
                return this.campaigns.filter((camp) => this.status.includes(camp.status.toString()) || (camp.alert === 1 && this.status.includes('alert')));
            },

            mediaFilteredByStatus() {
                return this.medias.filter((camp) => this.status.includes(camp.status.toString()) || (camp.alert === 1 && this.status.includes('alert')));
            },

            alertsFilteredByTypeAndBrand() {
                let filteredAlerts = this.alerts;

                if (this.filterByBrand.length > 0) {
                    const lowercasedBrandSort = this.filterByBrand.map((sortItem) => sortItem.toLowerCase());
                    filteredAlerts = filteredAlerts.filter((alert) => lowercasedBrandSort.includes(alert.details.brand_label.toLowerCase()));
                }

                if (this.filterByAlertType.length > 0) {
                    const lowercasedTypeSort = new Set(this.filterByAlertType.map((type) => type.toLowerCase()));

                    if (lowercasedTypeSort.has('budget_critical')) {
                        lowercasedTypeSort.add('budget_vigilant');
                    }

                    if (lowercasedTypeSort.has('budget_vigilant')) {
                        lowercasedTypeSort.add('budget_critical');
                    }

                    if (lowercasedTypeSort.has('perf_vigilant')) {
                        lowercasedTypeSort.add('perf_critical');
                    }

                    if (lowercasedTypeSort.has('perf_critical')) {
                        lowercasedTypeSort.add('perf_vigilant');
                    }

                    filteredAlerts = filteredAlerts.filter((alert) => lowercasedTypeSort.has(alert.type.toLowerCase()));
                }

                if (this.filterByMediaAlert.length > 0) {
                    const lowercasedTypeSort = this.filterByMediaAlert.map((media) => media.toLowerCase());
                    lowercasedTypeSort.includes('fbf') && lowercasedTypeSort.push('fbk');

                    filteredAlerts = filteredAlerts.filter((alert) => {
                        const mediaCategory = alert.details.media_label && alert.details.media_label.toLowerCase();

                        return mediaCategory && lowercasedTypeSort.includes(mediaCategory);
                    });
                }

                return filteredAlerts;
            },

            alertsFilteredByBrand() {
                if (this.filterByBrand.length > 0) {
                    const lowercasedSort = this.filterByBrand.map((sortItem) => sortItem.toLowerCase());

                    return this.alerts.filter((alert) => lowercasedSort.includes(alert.brand.toLowerCase()));
                } else {
                    return this.alerts;
                }
            },

            alertsFilteredByType() {
                if (this.filterByAlertType.length > 0) {
                    const lowercasedSort = this.filterByAlertType.map((type) => type.toLowerCase());

                    return this.alerts.filter((alert) => lowercasedSort.includes(alert.type.toLowerCase()));
                } else {
                    return this.alerts;
                }
            },

            sortedAlertsByType() {
                const alertTypeMapping = {
                    metrics_first_day: 'Metrics manquants premier jour',
                    unlink: 'Média non lié',
                    metrics_missing: 'Metrics manquants',
                    metrics_insufficient: 'Metrics insuffisants',
                    budget_vigilant: 'État du Budget',
                    budget_critical: 'État du Budget',
                    top_10: 'Erreur Top ten',
                    report_vigilant: 'Bilans en retard',
                    report_critical: 'Bilans en retard',
                    report_vigilant_csm: 'Bilans en retard',
                    report_critical_csm: 'Bilans en retard',
                    report_vigilant_direction: 'Bilans en retard',
                    report_critical_direction: 'Bilans en retard',
                    perf_vigilant: 'État de la Performance',
                    perf_critical: 'État de la Performance',
                };

                // Map the alert types
                const mappedAlerts = this.alertTypes.map((alert) => ({
                    value: alert.includes('report') ? 'report' : alert,
                    text: alertTypeMapping[alert] || alert,
                }));

                const uniqueAlerts = [];
                const seenTexts = new Set();

                mappedAlerts.forEach((alert) => {
                    if (!seenTexts.has(alert.text)) {
                        seenTexts.add(alert.text);
                        uniqueAlerts.push(alert);
                    }
                });

                // Sort the unique alerts
                return uniqueAlerts.sort((a, b) => a.text.localeCompare(b.text));
            },

            sortedAlertsBrands() {
                let uniqueBrandsMap = {};

                this.brandsAlerts.forEach((brand) => {
                    if (brand.label !== undefined && brand.label !== 'lmp') {
                        uniqueBrandsMap[brand.key] = brand;
                    }
                });

                return Object.values(uniqueBrandsMap)
                    .sort((a, b) => a.key.localeCompare(b.key))
                    .map((brand) => brand.label);
            },
            currentTable() {
                if (this.status.includes('0') || this.status.includes('1')) {
                    return 1;
                } else if (this.status.includes('999')) {
                    // Les camagnes/medias en alerte :
                    return 3;
                } else {
                    return 2;
                }
            },
        },
        mounted() {
            this.init();
            this.fetchAlerts();
        },
        methods: {
            async handleRefresh() {
                await this.fetchAlerts();
                await this.fetchMedias();
                await this.fetchCampaigns();
            },

            async sortAlertsByEndDateASC() {
                this.alerts.sort((a, b) => {
                    return new Date(a.end_date) - new Date(b.end_date);
                });
            },

            async sortAlertsByEndDateDESC() {
                this.alerts.sort((a, b) => {
                    return new Date(b.end_date) - new Date(a.end_date);
                });
            },

            async sortAlertsByStartDateASC() {
                this.alerts.sort((a, b) => {
                    return new Date(a.start_date) - new Date(b.start_date);
                });
            },

            async sortAlertsByStartDateDESC() {
                this.alerts.sort((a, b) => {
                    return new Date(b.start_date) - new Date(a.start_date);
                });
            },

            updateStatus(status) {
                this.$store.dispatch('app/updateFilterStatus', status);
            },

            updateCampaignsList(campaignsList) {
                this.$store.dispatch('app/updateCampaignsList', campaignsList);
                this.$root.toggleGlobalLoading(false);
            },

            async updateMediasList(mediasList) {
                this.$store.dispatch('app/updateMediasList', mediasList);
                this.$root.toggleGlobalLoading(false);
            },

            async updateAlertsList(alerts) {
                this.$store.dispatch('app/updateAlertsList', alerts);
                this.$root.toggleGlobalLoading(false);
            },

            async updateGlobalAlerts(alerts) {
                // Alerts medias ET parents
                this.$store.dispatch('app/updateGlobalAlerts', alerts);
                this.$root.toggleGlobalLoading(false);
            },

            allstatus() {
                this.updateStatus(['0', '1', '5', '7', '8', '9', '10', '20', '30']);
            },
            nonestatus() {
                this.updateStatus([]);
            },

            calculDepositeAndTotalPrices(data, campaign) {
                let totalPrice = 0;
                let depositPrice = 0;
                let creaType = 1;

                let desktopBannerAmount = 80;
                let mobileBannerAmount = 100;
                let facebookBannerAmount = 50;

                for (let line of data.lines) {
                    depositPrice += parseInt(line.price_excluding_taxes);
                    if (data.finalData.length === 0) totalPrice += parseInt(line.negotiated_price_excluding_taxes);

                    if (campaign.details?.creaType == 2) {
                        creaType = 2;
                        switch (line.media_category) {
                            case 'DSK':
                                depositPrice += desktopBannerAmount;
                                if (data.finalData.length === 0) totalPrice += desktopBannerAmount;
                                break;
                            case 'MOB':
                                depositPrice += mobileBannerAmount;
                                if (data.finalData.length === 0) totalPrice += mobileBannerAmount;
                                break;
                            case 'FBK':
                                depositPrice += facebookBannerAmount;
                                if (data.finalData.length === 0) totalPrice += facebookBannerAmount;
                                break;
                        }
                    }
                }

                if (data.finalData.length > 0) {
                    for (let line of data.finalData) {
                        if (line.amount) {
                            totalPrice += parseInt(line.amount);
                        } else {
                            for (let line of data.lines) {
                                totalPrice += parseInt(line.negotiated_price_excluding_taxes);
                            }
                        }
                        if (campaign.details?.creaType == 2 && line.amount > 0) {
                            switch (line.media) {
                                case 'DSK':
                                    totalPrice += desktopBannerAmount;
                                    break;
                                case 'MOB':
                                    totalPrice += mobileBannerAmount;
                                    break;
                                case 'FBK':
                                    totalPrice += facebookBannerAmount;
                                    break;
                            }
                        }
                    }
                }

                if (campaign.details.couponData) {
                    depositPrice = depositPrice - campaign.details.couponData[0]?.amount / 100;
                    totalPrice = totalPrice - campaign.details.couponData[0]?.amount / 100;
                }

                if (depositPrice != 0 && totalPrice != 0) {
                    depositPrice = depositPrice / 2; // recopier tel quel mais ¯\_(ツ)_/¯
                    totalPrice = totalPrice - depositPrice;
                }

                var finalPriceDetails = totalPrice + depositPrice;
                // SMS case
                if (campaign.details.provider !== undefined) {
                    if (campaign.details.provider == 'wellpack' && campaign.details.price !== undefined) {
                        depositPrice = campaign.details.price;
                        finalPriceDetails = campaign.details.price;
                    }
                }
                return {
                    depositPrice,
                    totalPrice,
                    finalPriceDetails,
                    creaType,
                };
            },

            async fetchCampaigns() {
                this.isLoading = true;
                let uniquesBrandsCampaigns = [];

                try {
                    const { data: campaigns } = await this.$store.dispatch('app/getCampaignValidation');
                    const formattedCampaigns = [];
                    const brands = new Set();

                    campaigns.forEach(async (camp) => {
                        if (camp.brand && camp.brand !== '') {
                            brands.add(camp.brand);
                        }

                        camp.brand ? camp.brand.charAt(0).toUpperCase() + camp.brand.slice(1) : '';

                        if (camp.brand) {
                            camp.brandIcon = await this.getBrandIcon(camp.brand);
                        }
                        camp.endDateCampaign = camp.end_date;

                        if (camp.details && camp.details.validationData) {
                            camp.budget = camp.details.validationData.details.total_price;
                        }

                        if (camp.budget && camp.left_to_pay && camp.budget > camp.left_to_pay) {
                            camp.paid = camp.budget / 2;
                        }

                        if (camp.details && camp.details.lines) {
                            camp.children = camp.details.lines.length;
                        }

                        camp.capitalizedBrands = Array.from(brands).sort();

                        formattedCampaigns.push(this.formatCampaignData(camp));
                    });

                    _.forEach(this.medias, (media) => {
                        const matchingCampaign = _.find(campaigns, { campaign_code: media.parent });

                        if (matchingCampaign) {
                            if (!matchingCampaign.details) {
                                matchingCampaign.details = {};
                            }

                            if (!matchingCampaign.details.children) {
                                matchingCampaign.details.children = [];
                            }

                            matchingCampaign.details.children.push(media);
                        }
                    });

                    uniquesBrandsCampaigns = await this.getUniquesBrands(campaigns);

                    await this.updateCampaignsList(formattedCampaigns);
                    await this.$store.dispatch(
                        'app/getBrandsList',
                        Array.from(brands).map((brand) => brand.charAt(0).toUpperCase() + brand.slice(1))
                    );

                    await this.$store.dispatch('app/getBrandsCampaigns', uniquesBrandsCampaigns);
                } catch (error) {
                    console.error(error);
                    this.$root.toggleGlobalLoading(false);
                    this.$toast({
                        component: 'toast',
                        props: {
                            title: 'Erreur sur la récupération des campagnes',
                            icon: 'x-circle',
                            variant: 'danger',
                        },
                    });
                } finally {
                    this.isLoading = false;
                }
            },

            async fetchMedias() {
                this.isLoading = true;
                let uniquesBrandsMedias = [];
                let uniquesMedias = [];

                try {
                    const { data: mediasList } = await this.$store.dispatch('app/getChildrenCampaignDetails');

                    const capitalizedMedias = [];

                    for (const media of mediasList) {
                        media.endDateMedia = media.end_date;
                        media.startDateMedia = media.start_date;
                        this.formatCampaignData(media);

                        if (media.media_support_code && media.media_support_code !== '' && media.media_support_code !== 'SMS' && !capitalizedMedias.includes(media.media_support_code)) {
                            capitalizedMedias.push(media.media_support_code);
                        }

                        if (media.brand) {
                            media.brandIcon = await this.getBrandIcon(media.brand);
                        }

                        if (media.media_support_code) {
                            media.icon = this.getIcon(media.media_support_code);
                        }
                    }

                    uniquesBrandsMedias = await this.getUniquesBrands(mediasList);

                    _.forEach(this.alerts, (alert) => {
                        const matchingCampaign = _.find(mediasList, { campaign_code: alert.campaign_code });

                        if (matchingCampaign) {
                            matchingCampaign.isAlert = true;
                        }
                    });

                    uniquesMedias = await this.getUniquesMedias(mediasList);

                    await this.updateMediasList(mediasList);
                    await this.$store.dispatch('app/getMediasList', uniquesMedias);
                    await this.$store.dispatch('app/getBrandsMedias', uniquesBrandsMedias);
                } catch (error) {
                    console.error(error);
                    this.$root.toggleGlobalLoading(false);
                    this.$toast({
                        component: 'toast',
                        props: {
                            title: 'Erreur sur la récupération des médias',
                            icon: 'x-circle',
                            variant: 'danger',
                        },
                    });
                } finally {
                    this.isLoading = false;
                }
            },

            async fetchAlerts() {
                try {
                    let uniquesBrandsAlerts = [];
                    let uniquesMediasAlerts = [];
                    let capitalizedMediasFromAlerts = [];

                    const alertTypes = [];

                    this.isLoading = true;
                    const res = await this.$store.dispatch('app/getAlerts');
                    let alerts = res;
                    const brands = new Set();

                    // Types d'alerte qui nécessitent alert.isDanger = true
                    const redAlertTypes = new Set([
                        'metrics_first_day',
                        'unlink',
                        'metrics_missing',
                        'metrics_insufficient',
                        'top_10',
                        'budget_critical',
                        'perf_critical',
                        'report_critical',
                        'report_critical_csm',
                        'report_critical_direction',
                    ]);

                    // Trie les alertes en mettant d'abord celles avec alert.type === 'unlink'
                    alerts.sort((a, b) => {
                        // Trie par alerte `metrics_first_day` en 1er
                        if (a.type === 'metrics_first_day' && b.type !== 'metrics_first_day') {
                            return -1;
                        } else if (a.type !== 'metrics_first_day' && b.type === 'metrics_first_day') {
                            return 1;
                        }

                        // Trie par alerte `unlink` en 2ème
                        if (a.type === 'unlink' && b.type !== 'unlink') {
                            return -1;
                        } else if (a.type !== 'unlink' && b.type === 'unlink') {
                            return 1;
                        }

                        // Trie par statut
                        if (a.status < b.status) {
                            return -1;
                        } else if (a.status > b.status) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });

                    for (const alert of alerts) {
                        if (redAlertTypes.has(alert.type)) {
                            alert.isDanger = true;
                        } else if (alert.type === 'budget_vigilant' || alert.type === 'perf_vigilant' || alert.type === 'report_vigilant') {
                            alert.isDanger = false;
                        }

                        if (alert.brand) {
                            alert.brandIcon = await this.getBrandIcon(alert.brand);
                            brands.add(alert.brand);
                        }
                        if (alert.details.media_category) {
                            alert.icon = this.getIcon(alert.details.media_category);

                            if (alert.details.media_category !== '' && alert.details.media_category !== 'SMS' && !capitalizedMediasFromAlerts.includes(alert.details.media_category)) {
                                capitalizedMediasFromAlerts.push(alert.details.media_category);
                            }
                        }

                        if (!alertTypes.includes(alert.type)) {
                            alertTypes.push(alert.type);
                        }

                        alert.capitalizedBrands = Array.from(brands).sort();
                    }

                    let alertsMedias = alerts.filter((alert) => !alert.details.isParent && alert.type !== 'top_10');

                    uniquesBrandsAlerts = await this.getUniquesBrands(alerts);
                    uniquesMediasAlerts = await this.getUniquesMedias(alerts);

                    await this.updateAlertsList(alertsMedias);
                    await this.updateGlobalAlerts(alerts);
                    await this.$store.dispatch('app/getAlertsTypes', alertTypes);
                    await this.$store.dispatch('app/getBrandsListAlerts', uniquesBrandsAlerts);
                    await this.$store.dispatch('app/getMediasFromAlerts', uniquesMediasAlerts);
                } catch (error) {
                    console.error(error);
                    this.$root.toggleGlobalLoading(false);
                    this.$toast({
                        component: 'toast',
                        props: {
                            title: 'Erreur sur la récupération des alerts',
                            icon: 'x-circle',
                            variant: 'danger',
                        },
                    });
                } finally {
                    this.isLoading = false;
                }
            },

            formatCampaignData(campaign) {
                let lines = {
                    finalData: campaign.details.finalData || [],
                    lines: campaign.details.lines || [],
                };
                let data = {};
                for (let media of this.availableMediasCode) {
                    let lineMedia = lines.lines.find((line) => line.media === media) || lines.lines.find((line) => line.media_category === media);
                    data[`${media}Amount`] = lineMedia?.amount || lineMedia?.negotiated_price_excluding_taxes || 0;
                    data[`${media}Volume`] = lineMedia?.volume || lineMedia?.quantity || 0;
                }

                data.comment = campaign.details.comment || '';

                data.totalPrice = 0;
                data.depositPrice = 0;
                data.creaType = 1;

                data = {
                    ...campaign,
                    _showDetails: false,
                    campaignCode: campaign.campaign_code,
                    status: campaign.status,
                    name: campaign.details.label_quotation,
                    startDate: campaign.start_date,
                    endDate: campaign.end_date,
                    isParent: campaign.is_parent,
                    ...data,
                    ...this.calculDepositeAndTotalPrices(lines, campaign),
                };
                data.pointText = campaign.details.point_text;
                if (Array.isArray(campaign.details.point_text)) {
                    data.pointText = campaign.details.point_text.toString();
                }
                data.pointText += `\n[${campaign.details.point_code}]`;

                return this.formatStatus(data);
            },

            formatStatus(campaign) {
                const today = moment();

                if (
                    ((campaign.status === 0 || campaign.status === 1) && moment(campaign.startDate).isAfter(today)) ||
                    ((campaign.status === 0 || campaign.status === 1) && moment(campaign.startDate).isSameOrAfter(today))
                ) {
                    // Non commencée
                    campaign.status = 0;
                } else if (campaign.status === 0 && moment(campaign.startDate).isSameOrBefore(today)) {
                    // Retard
                    campaign.status = 11;
                    campaign.late = true;
                } else if (campaign.status === 10) {
                    // A payer
                    campaign.status = 10;
                } else if (campaign.status === 7) {
                    campaign.status = 7;
                    campaign.value = 'traders';
                    campaign.message = 'En attente de validation des traders';
                } else if (campaign.status === 5) {
                    // Status 5 = Top10, a determiner apres
                    // campaign.status = 7;
                    // campaign.value = 'traders';
                    // campaign.message = 'En attente top 10';
                } else if (campaign.status === 8) {
                    campaign.status = 7;
                    campaign.value = 'csm';
                    campaign.message = 'En attente de validation du service client';
                } else if (campaign.status === 9) {
                    campaign.status = 7;
                    campaign.value = 'direction';
                    campaign.message = 'En attente de retour de la direction';
                }

                return campaign;
            },

            async init() {
                this.$root.toggleGlobalLoading(true);
                let right = JSON.parse(localStorage.getItem('rights'));

                if (right) {
                    if (right.client_service) {
                        if (right.client_service == 1) {
                            this.right = 'client_service';
                        }
                    } else if (right.direction) {
                        if (right.direction == 1) {
                            this.right = 'direction';
                        }
                    } else if (right.trading) {
                        if (right.trading == 1) {
                            this.right = 'trading';
                        }
                    }
                }

                if (this.campaigns.length < 1 || this.$store.state.app.campaignsModified) {
                    await this.fetchMedias();
                    await this.fetchCampaigns();

                    await this.$store.dispatch('app/modifiedMedias', false);
                    await this.$store.dispatch('app/modifiedCampaigns', false);
                } else {
                    this.$root.toggleGlobalLoading(false);
                }
            },

            async getUniquesMedias(campaigns) {
                try {
                    let uniquesMedias = [];
                    let uniquesMediasMap = {};

                    for (const campaign of campaigns) {
                        if (campaign.is_parent === 1) {
                            return null;
                        } else {
                            if (campaign.details.media_category && campaign.details.media_label) {
                                uniquesMediasMap[campaign.details.media_category] = {
                                    key: campaign.details.media_category,
                                    label: campaign.details.media_label,
                                };
                            }
                        }
                    }

                    uniquesMedias = Object.values(uniquesMediasMap).sort((a, b) => a.key.localeCompare(b.key));
                    return uniquesMedias;
                } catch (error) {
                    throw error;
                }
            },

            async getUniquesBrands(campaigns) {
                try {
                    let uniquesBrandsMedias = [];
                    let uniqueBrandsMap = {};

                    for (const campaign of campaigns) {
                        if (campaign.is_parent === 1) {
                            if (campaign.brand_label !== undefined && campaign.brand_label !== 'lmp') {
                                uniqueBrandsMap[campaign.brand_key] = {
                                    key: campaign.brand_key,
                                    label: campaign.brand_label,
                                };
                            }
                        } else {
                            if (campaign.details.brand_label !== undefined && campaign.details.brand_label !== 'lmp') {
                                uniqueBrandsMap[campaign.details.brand_key] = {
                                    key: campaign.details.brand_key,
                                    label: campaign.details.brand_label,
                                };
                            }
                        }
                    }

                    uniquesBrandsMedias = Object.values(uniqueBrandsMap).sort((a, b) => a.key.localeCompare(b.key));
                    return uniquesBrandsMedias;
                } catch (error) {
                    throw error;
                }
            },

            async filterByStatus(statusCode) {
                const statusToRemove = ['0', '1', '7', '8', '10', '20', '30', '999'];

                statusToRemove.forEach((code) => {
                    const index = this.status.indexOf(code);
                    if (index !== -1) {
                        this.status.splice(index, 1);
                    }
                });

                if (!this.status.includes(statusCode)) {
                    this.status.push(statusCode);
                }
            },

            async archived(campaigns) {
                this.archivedCampaigns = campaigns.data;
            },

            async getBrandIcon(brand) {
                if (brand) {
                    switch (brand.toLowerCase()) {
                        case 'ds':
                            return 'ds-icon';
                        case 'opel':
                            return 'opel-icon';
                        case 'citroen':
                            return 'citroen-icon';
                        case 'toyota':
                            return 'toyota-icon';
                        case 'volvo':
                            return 'volvo-icon';
                        case 'groupe_emil_frey':
                            return 'groupe_emil_frey-icon';
                        case 'honda':
                            return 'honda-icon';
                        case 'lexus':
                            return 'lexus-icon';
                        case 'mercedes-cars':
                            return 'mercedes-icon';
                        case 'mercedes-vans':
                            return 'mercedes-icon';
                        case 'peugeot':
                            return 'peugeot';
                        case 'sonance':
                            return 'sonance-icon';
                        case 'spoticar':
                            return 'spoticar-icon';
                        case 'stellantis':
                            return 'stellantis-icon';
                        case 'caravenue':
                            return 'car-avenue-icon';
                        case 'alliance':
                            return 'alliance-piscine-icon';
                        case 'cocktail':
                            return 'cocktail-piscine-icon';
                        case 'gbh':
                            return 'gbh-icon';
                        case 'nissan':
                            return 'nissan-icon';
                        case 'athena':
                            return 'athena-icon';
                        case 'chopard':
                            return 'chopard-icon';
                        default:
                            return '';
                    }
                }
            },

            getIcon(media) {
                if (media) {
                    switch (media) {
                        case 'FBF':
                        case 'FBK':
                            return 'facebook';
                        case 'WPI':
                            return 'waze';
                        case 'WTO':
                            return 'waze';
                        case 'YOU':
                            return 'youtube';
                        case 'DSK':
                            return 'desktop-solid';
                        case 'ADW':
                            return 'adwords';
                        case 'LIN':
                            return 'linkedin';
                        case 'MOB':
                            return 'mobile-screen-button-solid';
                        case 'ADP':
                            return 'play-solid';
                        case 'VID':
                            return 'video-solid';
                        case 'ADS':
                            return 'tv-solid';
                        case 'TF1':
                            return 'video-solid';
                        default:
                            return '';
                    }
                }
            },
        },
        watch: {
            medias() {
                // Restart fetchCampaigns if medias change so fetchCampaigns will always be call after fetchMedias
                this.fetchCampaigns();
            },

            async sortAlertBy(value) {
                switch (this.sortAlertBy) {
                    case 'startDateAsc':
                        await this.sortAlertsByStartDateASC();
                        break;

                    case 'endDateAsc':
                        await this.sortAlertsByEndDateASC();
                        break;

                    case 'startDateDesc':
                        await this.sortAlertsByStartDateDESC();
                        break;

                    case 'endDateDesc':
                        await this.sortAlertsByEndDateDESC();
                        break;

                    case 'default':
                        await this.fetchAlerts();
                        break;

                    // default:
                    //     await this.fetchAlerts();
                    //     break;
                }
                this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'sortBy', value });
            },
            currentTable: {
                handler(newValue, oldValue) {
                    if (oldValue !== newValue) {
                        this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'brandFilter', value: [] });
                        this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'mediaFilter', value: [] });
                        this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'typeFilter', value: [] });
                        this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'searchFilter', value: '' });
                        if (newValue === 2) {
                            this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'sortBy', value: 'startDateCampaign' });
                        } else if (newValue === 1) {
                            this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'sortBy', value: 'startDateMedia' });
                        } else if (newValue === 3) {
                            this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'sortBy', value: 'default' });
                            this.sortAlertBy = 'default';
                        }
                    }
                },
            },

            filterByBrand(value) {
                this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'brandFilter', value });
            },
            filterByMediaAlert(value) {
                this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'mediaFilter', value });
            },
            filterByAlertType(value) {
                this.$store.dispatch('app/updateGlobalConfigPageCampaigns', { field: 'typeFilter', value });
            },
            globalConfigPageCampaigns: {
                immediate: true,
                deep: true,
                handler() {
                    if (this.status.includes('999')) {
                        this.filterByBrand = this.globalConfigPageCampaigns.brandFilter;
                        this.filterByMediaAlert = this.globalConfigPageCampaigns.mediaFilter;
                        this.filterByAlertType = this.globalConfigPageCampaigns.typeFilter;
                        this.sortAlertBy = this.globalConfigPageCampaigns.sortBy;
                    }
                },
            },
        },
    };
</script>

<style scoped lang="scss">
    .filters {
        margin: 0;
        min-width: 100%;
        padding-left: 0;
        padding-right: 0;

        .filters-container {
            margin: 26px 0;
        }

        .alerts-container {
            font-weight: bold;
            display: flex;
            justify-content: space-between;
            padding: 10px;

            .dropdown {
                min-width: 210px;
                margin-left: 10px;
                margin-right: 10px;

                .list {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .bv-no-focus-ring {
                    font-size: 13px !important;
                    width: 245px;
                    min-width: 245px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
            }

            .media-dropdown {
                min-width: 130px;

                .list {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }

    .custom-label label {
        font-size: 11px;
    }

    .dark-layout {
        .custom-select {
            background-color: #161d31 !important;
            box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.24) !important;

            .sort-select-option {
                background-color: #283046;

                cursor: pointer;

                &.first {
                    margin-top: 5px;
                }
            }
            &:focus {
                border-color: #3b4253 !important;
                box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
            }
        }
    }

    .custom-select:focus {
        border-color: #d8d6de !important;
        box-shadow: none !important;
    }

    .custom-checkbox .custom-control-label,
    .custom-radio .custom-control-label {
        :v-deep {
            font-size: 13rem !important;
            line-height: 19px;
        }
    }

    .alert-dropdown,
    .media-dropdown {
        ::v-deep .custom-control-label {
            font-size: 13px;
            line-height: 19px;
        }
    }

    .card {
        line-height: normal;
    }
</style>
